html,
body,
#root {
  font-size: 16px;
}
@media screen and (max-height: 800px) {
  html,
  body,
  #root {
    font-size: 14px;
  }
}
