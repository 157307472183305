/*
/* Montserrat Font
*/

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/montserrat/montserrat-v25-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat/montserrat-v25-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/montserrat/montserrat-v25-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../fonts/montserrat/montserrat-v25-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/montserrat/montserrat-v25-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/montserrat/montserrat-v25-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat/montserrat-v25-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/montserrat/montserrat-v25-latin-500.woff') format('woff'),
    /* Modern Browsers */
      url('../fonts/montserrat/montserrat-v25-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/montserrat/montserrat-v25-latin-500.svg#Montserrat')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/montserrat/montserrat-v25-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat/montserrat-v25-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/montserrat/montserrat-v25-latin-600.woff') format('woff'),
    /* Modern Browsers */
      url('../fonts/montserrat/montserrat-v25-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/montserrat/montserrat-v25-latin-600.svg#Montserrat')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/montserrat/montserrat-v25-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/montserrat/montserrat-v25-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/montserrat/montserrat-v25-latin-700.woff') format('woff'),
    /* Modern Browsers */
      url('../fonts/montserrat/montserrat-v25-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/montserrat/montserrat-v25-latin-700.svg#Montserrat')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}

/*
/* Rajdhani Font
*/

/* rajdhani-regular - latin */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/rajdhani/rajdhani-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/rajdhani/rajdhani-v15-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/rajdhani/rajdhani-v15-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/rajdhani/rajdhani-v15-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('../fonts/rajdhani/rajdhani-v15-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/rajdhani/rajdhani-v15-latin-regular.svg#Rajdhani')
      format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* rajdhani-500 - latin */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/rajdhani/rajdhani-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/rajdhani/rajdhani-v15-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/rajdhani/rajdhani-v15-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/rajdhani/rajdhani-v15-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/rajdhani/rajdhani-v15-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/rajdhani/rajdhani-v15-latin-500.svg#Rajdhani') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* rajdhani-700 - latin */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/rajdhani/rajdhani-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/rajdhani/rajdhani-v15-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/rajdhani/rajdhani-v15-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/rajdhani/rajdhani-v15-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/rajdhani/rajdhani-v15-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/rajdhani/rajdhani-v15-latin-700.svg#Rajdhani') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* rajdhani-600 - latin */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/rajdhani/rajdhani-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/rajdhani/rajdhani-v15-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/rajdhani/rajdhani-v15-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/rajdhani/rajdhani-v15-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/rajdhani/rajdhani-v15-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/rajdhani/rajdhani-v15-latin-600.svg#Rajdhani') format('svg'); /* Legacy iOS */
  font-display: swap;
}
